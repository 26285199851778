/*********************** Navbar ***********************/


body {
  overflow-x: hidden;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

body::-webkit-scrollbar {
  display: none; /* Chrome, Safari, and other WebKit-based browsers */
}

.scrolled{
    background-color: #030A74;
}
.scrolled .lists{
    color: #fff;
    padding: 0.8rem 0.6rem;
}
.notScrolled .lists{
    color: black;
    padding: 0.8rem 0.6rem;
}


/*********************** Home ***********************/

  .heading{
    animation: slideRight 4s forwards;
  }
  @keyframes slideRight{
    from {
        transform: translateX(-40%);
    }
    to {
        transform: translateX(0);
    }
  }

/*********************** Footer ***********************/

.footer p {
    font-weight: 400;
    font-size: 14px;
    color: #B8B8B8;
    letter-spacing: 0.5px;
}

.left{
  animation: slideAnimationLeft 6s forwards;
}
@keyframes slideAnimationLeft {
  0% {
    transform: translateX(-30%);
  }
  50% {
    transform: translateX(40%);
  }
  100% {
    transform: translateX(0%);
  }
}

.right{
  animation: slideAnimationRight 6s backwards;
}
@keyframes slideAnimationRight {
  0% {
    transform: translateX(30%);
  }
  50% {
    transform: translateX(-40%);
  }
  100% {
    transform: translateX(0%);
  }
}

.left-img-div{
  position: absolute;
  bottom: -4%;
  left: 50%;
  transform: translateX(-133%);
}
.right-img-div{
  position: absolute;
  bottom: -4%;
  right: 50%;
  transform: translateX(133%);
}

.video-div{
  display: flex;
  justify-content: center;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -1.7%;
}

@media (min-width:336px) and (max-width:766px){
  .left-img-div{
    bottom: -4%;
  }
  .right-img-div{
    bottom: -4%;
  }
  
  .video-div{
    bottom: -1.4%;
  }  
}

.no-scrollbar::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}
.no-scrollbar {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}